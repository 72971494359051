// reactstrap components
import React from "react";
import { Container, Col, Row, Button } from "reactstrap";
import Register from "components/Register";

function LandingPageHeader() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const social = [
    {
      name: "facebook",
      link: "https://www.facebook.com/ktuniexpo/",
    },
    {
      name: "linkedin",
      link:
        "https://www.linkedin.com/showcase/uniexpo-dubai/about/?viewAsMember=true",
    },
    {
      name: "instagram",
      link: "https://www.instagram.com/uniexpo.kt/",
    },
  ];
  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/images/background.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionX: "center",
          minHeight: "100vh",
        }}
        data-parallax={true}
      >
        <div className="overlay-secondary"></div>
        <Container>
          <Row>
            <Col lg={6} className="align-self-center text-center">
              <img
                src={require(`assets/images/logo/logo.png`)}
                width="100%"
                alt="khaleej"
              />
              <div className="mb-3">
                {social.map((s) => (
                  <Button
                    className="btn-neutral btn-just-icon m-1 p-1"
                    style={{ color: "#1d1960" }}
                    href={s.link}
                  >
                    <i className={`fa fa-${s.name}`} />
                  </Button>
                ))}
              </div>

              <h1
                className="text-700 text-white text-wrap"
                style={{
                  fontSize: size ? "2rem" : "1.5rem",
                }}
              >
                OPEN HOUSE FOR JANUARY 2021 INTAKE
              </h1>
              <h1
                className="text-700 text-white"
                style={{
                  fontSize: size ? "1.5rem" : "1rem",
                }}
              >
                19th November 2020, 10 AM – 4 PM
              </h1>
              <h1
                className="text-700 text-white"
                style={{
                  fontSize: size ? "1.5rem" : "1rem",
                }}
              >
                20th November 2020, 1 PM – 5 PM
              </h1>
            </Col>
            <Col lg={6} className="mt-5">
              <Register type="REGISTER" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
