import React from "react";

import Footer from "components/Footer.js";

import { Container, Row, Col, Button } from "reactstrap";

function ThankYou() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/images/background.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionX: "center",
          minHeight: "100vh",
        }}
        data-parallax={true}
      >
        <div className="overlay-secondary"></div>
        <Container>
          <Row className="pt-5">
            <Col
              lg={6}
              className=" border-right   align-self-center text-center"
            >
              <img
                src={require(`assets/images/logo/logo.png`)}
                width="100%"
                alt="khaleej"
              />
              <h1
                className="text-700 text-white text-wrap"
                style={{
                  fontSize: size ? "2rem" : "1.5rem",
                }}
              >
                OPEN HOUSE FOR JANUARY 2021 INTAKE
              </h1>
              <h1
                className="text-700 text-white"
                style={{
                  fontSize: size ? "1.5rem" : "1rem",
                }}
              >
                19-20 NOVEMBER 2020
              </h1>
              <h1
                className="text-700 text-white"
                style={{
                  fontSize: size ? "1.5rem" : "1rem",
                }}
              >
                10 AM - 4 PM on both days
              </h1>
            </Col>
            <Col lg={6} className=" mt-5 align-self-center">
              <h1 className="text-white">
                Thank you for registering to attend KT UniExpo Open House, to be
                held on 19-20 November. We look forward to welcoming you to the
                virtual event. Stay updated with all the latest information
                about the event.
              </h1>
              <h1>
                {social.map((s) => (
                  <Button
                    className="btn-neutral btn-just-icon m-1 p-1"
                    style={{ color: "#1d1960" }}
                    href={s.link}
                  >
                    <i className={`fa fa-${s.name}`} />
                  </Button>
                ))}{" "}
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              
            </Col>
          </Row>
        </Container>
      </div> */}

      <Footer />
    </>
  );
}

export default ThankYou;

const social = [
  {
    name: "facebook",
    link: "https://www.facebook.com/ktuniexpo/",
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/showcase/uniexpo-dubai/about/?viewAsMember=true",
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/uniexpo.kt/",
  },
];
