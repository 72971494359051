import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "#f0672c",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h2 className="text-white text-center text-700">
                WHY SHOULD YOU PARTICIPATE?
              </h2>
            </Col>
            <Col lg={8}>
              <h3 className="text-white text-400">
                <br />
                KT UniExpo is an exclusive two-day event dedicated to
                enrollments for January 2021. The open house will enable
                prospective students and their families to virtually engage with
                University faculty and have a direct discussion with the
                admissions team about their academic programs and entry
                requirements for January.
                <br />
                <br />
                Participating in the KT Open House means:
                <ul>
                  <li>
                    A virtual event with direct access to your university for
                    students
                  </li>
                  <li>
                    Holding 1-1 video conversations and discussions with
                    prospective students
                  </li>
                  <li>Handing out your University’s course programmes</li>
                  <li>
                    A dedicated post-event follow up with all prospective
                    students
                  </li>
                  <li>
                    Opportunity to hold virtual live presentations highlighting
                    your university
                  </li>
                  <li>
                    Branding and extensive marketing campaign 2 weeks prior to
                    the event, promoting your participation
                  </li>
                </ul>
              </h3>
            </Col>
            <Col lg={12} className="text-center">
              <Button
                className="px-3 py-2  rounded-0"
                color="info"
                style={{ minWidth: "200px" }}
                href="https://www.ktuniexpo.com/Inquiry/index/Exhibit"
              >
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  ENQUIRE NOW
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
