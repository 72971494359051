import React from "react";
import { Row, Container, Col, Button } from "reactstrap";

function DemoFooter() {
  return (
    <footer
      className="footer pt-5 py-3"
      style={{
        backgroundColor: "#f3faff",
        backgroundImage: "linear-gradient(to bottom, #fff, #e2f2ff)",
      }}
    >
      <Container fluid>
        <Row className="justify-content-center py-5">
          <Col lg={4} className="py-2 text-center">
            <h3>Organised By</h3>
            <a href="https://www.khaleejtimes.com/">
              <img
                src={require(`assets/images/logo/khaleejdark.png`)}
                style={{ maxWidth: "300px" }}
                width="100%"
                alt="Finnovex"
              />
            </a>
          </Col>
          <Col lg={4} className="text-center py-2 align-self-center">
            <h3 className="d-inline">
              <a href="https://www.khaleejtimes.com/privacy-policy">
                Privacy Policy
              </a>
            </h3>
          </Col>

          <Col lg={4} className="text-center align-self-center">
            <h3 className="mb-0">Connect With Us</h3>
            {social.map((s) => (
              <Button
                className="btn-neutral btn-just-icon m-1 p-1"
                style={{ color: "#1d1960" }}
                href={s.link}
              >
                <i className={`fa fa-${s.name}`} />
              </Button>
            ))}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} className={"text-center"}>
            <p>
              ©2020 Galadari Printing and Publishing LLC. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;

const social = [
  {
    name: "facebook",
    link: "https://www.facebook.com/ktuniexpo/",
  },
  {
    name: "linkedin",
    link:
      "https://www.linkedin.com/showcase/uniexpo-dubai/about/?viewAsMember=true",
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/uniexpo.kt/",
  },
];
