import React from "react";

import LandingPageHeader from "components/Headers/LandingPageHeader.js";

import Footer from "components/Footer.js";
import Textgrid from "components/TextGrid.js";
function RegisterPage() {
  return (
    <>
      <LandingPageHeader />
      <Textgrid />
      <Footer />
    </>
  );
}

export default RegisterPage;
