import React from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Input,
  Form,
  Alert,
  Spinner,
} from "reactstrap";
import { Redirect } from "react-router-dom";

function Register({ type, successMsg }) {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get("utm_source");
  const utm_medium = urlParams.get("utm_medium");
  const utm_campaign = urlParams.get("utm_campaign");

  const [details, setDetails] = React.useState({
    type,
    utm_source,
    utm_medium,
    utm_campaign,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    // const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    // if (reg.test(details.email)) {
    //   setLoading(false);
    //   setError("Please enter your business email only");
    //   return null;
    // }

    fetch("https://us-central1-uniexpo2020.cloudfunctions.net/api/email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        // setSuccess(
        //   successMsg
        //     ? successMsg
        //     : "Thank you for your request. We'll get back to you shortly."
        // );
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };
  let inputs = content;
  if (type === "BROCHURE") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "REPORT") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "SPONSOR") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6, 7].includes(c.id));
  }
  if (type === "MEDIA") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5].includes(c.id));
  }
  if (redirect) {
    return <Redirect to={`/thank-you`} />;
  }
  return (
    <Card
      style={{
        backgroundColor: "#25aae1",
      }}
    >
      <Container className="my-auto text-white text-uppercase text-400 p-5">
        {type === "REGISTER" && <p></p>}
        <Form onSubmit={onSubmitForm} className="register-form">
          <Row>
            <Col lg={12}>
              <h2>REGISTER TO ATTEND</h2>
            </Col>
          </Row>
          <Row className="justify-content-center text-white">
            {inputs.map((i) => (
              <Col lg={6} className="my-2" key={i.name}>
                <label>{i.placeholder}*</label>
                <Input
                  placeholder={i.placeholder}
                  type={i.type}
                  name={i.name}
                  onChange={onChangeDetails}
                  required
                />
              </Col>
            ))}
            <Col lg={12} className="my-2">
              <label>Preferred University*</label>
              <Input
                type="select"
                name="university"
                id=""
                onChange={onChangeDetails}
                required
              >
                <option value="">Select</option>
                <option>Abu Dhabi University (ADU)</option>
                <option>Heriot-Watt University Dubai</option>
                <option>Middlesex University Dubai</option>
                <option>Rochester Institute of Technology</option>
                <option>Murdoch University</option>
                <option>Canadian University Dubai</option>
                <option>American University of Sharjah</option>
              </Input>
            </Col>
            <Col lg={12} className="my-2">
              <label>What courses/ training are you interested in?*</label>
              <Input
                type="text"
                name="interest"
                id="interest"
                onChange={onChangeDetails}
                required
              />
            </Col>
            <Col lg={12} className="text-center pt-2">
              <p style={{ textTransform: "none" }}>
                <a href="/privacy-policy">
                  Click here to read our privacy policy here.
                </a>
              </p>
            </Col>
          </Row>

          {loading ? (
            <span>
              <Spinner className="mt-3" color="warning" size="sm" />
            </span>
          ) : (
            <Button
              block
              className="btn text-white text-700 mt-3"
              color="primary"
              size="lg"
              type="submit"
              disabled={loading}
            >
              Submit
            </Button>
          )}
        </Form>
      </Container>
      <CreateAlert success={success} error={error} />
    </Card>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? "success" : "danger";
  const message = success ? success : error;
  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;

const content = [
  { id: 1, name: "first_name", type: "text", placeholder: "First Name" },
  { id: 2, name: "last_name", type: "text", placeholder: "Last Name" },
  { id: 2, name: "age", type: "date", placeholder: "Date of Birth" },
  { id: 2, name: "email", type: "text", placeholder: "Email" },
  { id: 4, name: "phone", type: "text", placeholder: "Mobile" },
  { id: 7, name: "country", type: "text", placeholder: "Country" },
  { id: 7, name: "city", type: "text", placeholder: "City" },
  {
    id: 5,
    name: "company",
    type: "text",
    placeholder: "School / Company Name",
  },

  // {
  //   id: 6,
  //   name: "university",
  //   type: "text",
  //   placeholder: "Preferred University",
  // },
  // { id: 8, name: "nationality", type: "text", placeholder: "Nationality" },
];
